import { useCommonApi } from '~/api/common'
import { IContact } from '~/types/footer'
export const useContact = () => {
  const { $axios } = useNuxtApp()
  const { BRANCH_CONTACT } = useCommonApi()
  const contacts = ref<IContact[]>([])

  const fetchBranchContact = async () => {
    try {
      const { data: response } = await $axios.get(BRANCH_CONTACT)
      if (response && response.status === 'OK') {
        contacts.value = response.data.slice(0, 3)
      }
    } catch (error) {
      console.log(error)
    }
    return []
  }

  return {
    fetchBranchContact,
    contacts
  }
}
