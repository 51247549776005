import { PAGE } from '~/constants/router'
import { PATH_FOOTER_URL } from '~/constants/path'
import { IFooter } from '~/types/footer'
import { URLS } from '~/config/page-url'

export const footer: IFooter = {
  footerMenu: [
    {
      link: URLS.PROMOTION,
      text: 'Khuyến Mãi',
      label: ''
    },
    {
      link: URLS.ABOUT_US,
      text: 'Giới Thiệu',
      label: ''
    },
    {
      link: PAGE.CONDITIONS,
      text: 'Điều Khoản Điều Kiện',
      label: ''
    },
    {
      link: PAGE.GUIDE,
      text: 'Hướng Dẫn Nạp Rút',
      label: ''
    },
    {
      link: PAGE.GUIDE,
      text: 'Hướng Dẫn Đăng Ký',
      label: ''
    },
    {
      link: PAGE.NEWS,
      text: 'Tip Bóng Đá',
      label: ''
    },
    {
      link: PAGE.NEWS,
      text: 'Soi Kèo',
      label: ''
    }
  ],
  footerBank: [
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_donga.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_sacom.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_acb.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank-mb.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_bidv.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/vp-bank.svg`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_vietcom.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_techcom.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_vietin.png`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_exim.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_tp.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}bank/bank_shb.svg`
    }
  ],
  footerPartner: [
    {
      imgSrc: `${PATH_FOOTER_URL}partner/go88.webp`,
      class: 'go88'
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/rik.webp`,
      class: 'b52'
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/b52.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/iwin.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/techplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/saba.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/bestsoft.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/tomhorn.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/spinomenal.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/bbtech.webp`,
      class: 'BBTech'
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/playngo.webp`,
      class: 'playngo'
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/spribe.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/pragmaticplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/cq9.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/microgaming.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/evoplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/qtech.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/habanero.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/onegame.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/betradar.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/redtiger.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/netent.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/jdpgaming.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/wazdan.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/pg.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/simpleplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/pariplay.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/sagaming.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/yeebet.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/jili.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/btg.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/nonimitcity.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/fachai.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/askmebet.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/askmelotto.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/digmaan.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/kingmaker.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/nextspin.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/hacksawgaming.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/oneapi.webp`
    },
    {
      imgSrc: `${PATH_FOOTER_URL}partner/dreamgaming.webp`
    }
  ],
  footerPaymentMethods: [
    {
      text: 'Codepay',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/codepay.svg`
    },
    {
      text: 'Ngân Hàng',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/ngan-hang.svg`
    },
    {
      text: 'Viettel Money',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/viettel-money.svg`
    },
    {
      text: 'MoMo',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/momo.svg`
    },
    {
      text: 'Thẻ Cào',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/the-cao.svg`
    },
    {
      text: 'Tiền Ảo',
      imgSrc: `${PATH_FOOTER_URL}payment-methods/tien-ao.svg`
    }
  ],
  quickLink: [
    {
      link: PAGE.SOI_KEO,
      text: 'Soi Kèo'
    },
    {
      link: PAGE.NHAN_DINH_BONG_DA,
      text: 'Nhận Định Bóng Đá'
    },
    {
      link: PAGE.TIP_BONG_DA,
      text: 'Tip Bóng Đá'
    },
    {
      link: PAGE.KEO_BONG_RO,
      text: 'Kèo Bóng Rổ'
    },
    {
      link: PAGE.LO_DE_ONLINE,
      text: 'Lô Đề Online'
    },
    {
      link: PAGE.NO_HU,
      text: 'Nổ Hũ'
    },
    {
      link: PAGE.BAN_CA,
      text: 'Bắn Cá'
    },
    {
      link: PAGE.TAI_XIU,
      text: 'Tài Xỉu'
    }
  ]
}
