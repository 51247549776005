<template>
  <div class="sub-menu">
    <div v-if="!useGameItem" class="sub-menu__container">
      <div
        v-for="(subItem, subIndex) in submenu"
        :key="`${gameType}${subIndex}`"
        class="sub-menu__item"
        :class="{ 'sub-menu__sport': gameType === 'sports', 'sub-menu__games': gameType === 'games' }"
        @click.stop="onClickItem(subItem)"
      >
        <BaseImg
          v-if="subItem.logo || subItem.icon"
          class="sub-menu__item--thumb-game"
          :src="subItem.logo || subItem.icon"
          alt="icon"
        />
        <BaseImg v-if="subItem.imgBackground" class="sub-menu__item--background" :src="subItem.imgBackground" />
        <div v-if="subItem.title || subItem.name" class="sub-menu__item--title">
          {{ subItem.title || subItem.name }}
        </div>
        <div class="sub-menu__item--subtitle">{{ `(${getTotalGameFromGameAlias(subItem?.alias)} games)` }}</div>
      </div>
    </div>
    <div v-else class="sub-menu__container">
      <div class="isGames">
        <template v-for="(item, index) in submenu" :key="index">
          <CommonGamesItemGame
            :item="item"
            :class="`${item?.class ? item?.class : ''}`"
            @click.stop="onClickItem(item)"
          />
        </template>
        <div class="total-thumb">
          <span>
            Xem tất cả
            <BaseImg class="thumb" src="assets/images/icon/icon-double-arrow.svg" />
          </span>
          <div class="total-game">{{ `(${getTotalGameFromGameAlias(gameType)} games)` }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import BaseImg from '~/components/common/base-img.vue'
import CommonGamesItemGame from '~/components/common/games/item-game'
import { useGameStore } from '~/store/game'

const { $pinia } = useNuxtApp()
const storeGames = useGameStore($pinia)
const { providerGames, providerCasino } = storeToRefs(storeGames)

const props = defineProps({
  gameType: {
    type: String,
    default: () => undefined
  },
  submenu: {
    type: Array,
    default: () => []
  },
  onClickItem: {
    type: Function,
    default: () => {}
  }
})
const useGameItem = computed(() => ['quay-so', 'table-game', 'no-hu'].includes(props.gameType))

const getTotalGameFromGameAlias = (alias) => {
  if (props.gameType === 'livecasino') {
    const foundCasino = providerCasino.value?.find((itemGame) => itemGame?.alias === alias)
    return foundCasino?.total_games || 0
  } else {
    const foundGame = providerGames.value?.find((itemGame) => itemGame?.alias === alias)
    return foundGame?.total_games || 0
  }
}
</script>

<style lang="scss" scoped src="assets/scss/components/desktop/layouts/header/submenu.scss"></style>
